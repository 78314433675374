/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  ImageList,
  ImageListItem,
  InputBase,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { get, search } from '../../api'
import Movie from '../../interfaces/Movie'
import BoxMovie from '../../components/BoxMovie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownAZ, faArrowDownZA, faArrowDown19, faArrowDown91, faFireFlameCurved, faCalendarDay, faCalendar, faHeart, faA } from '@fortawesome/free-solid-svg-icons'
import { Backspace, Search } from '@mui/icons-material'
import { BoxPrimaryStyle, BoxSecundaryStyle, FormControlStyle, PaperStyle } from './style'
import { useMovie } from '../../context/MovieContext'
import GetApi from '../../interfaces/GetApi'
import MovieListSkeleton from '../../components/MovieListSkeleton'
import Footer from '../../components/Footer'
import Title from '../../components/SEO/Title'
import ReturnMovie from '../../interfaces/ReturnMovie'
import { GetStatusMovie } from '../../helpers/GetStatusMovie'

export default function MoviesPage() {
  const [loading, setLoading] = useState<boolean>(true)
  const [query, setQuery] = useState<string>('')
  const [result, setResults] = useState<ReturnMovie>()
  const [type, setType] = useState<string>('now-playing')
  const [order, setOrder] = useState<string>('popularity-DESC')
  const { userData } = useMovie()
  const token = userData.token
  const getMovies = async (currentPage: number = 1) => {
    setLoading(true)
    const [field, direction] = order.split('-')
    const getMovies: GetApi = {
      term: query !== '' ? query.trim() : type,
      currentPage,
      order: {
        field,
        direction
      },
      token: token,
      adult: false
    }
    let response = query !== '' ? await search(getMovies) : await get(getMovies)

    if (token) {
      response.data = await GetStatusMovie(response.data, token)
    }
    setResults(response)
    setLoading(false)
  }
  const breakpoints = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
  const getColumns = (width: any) => {
    if (width < breakpoints.sm) {
      return 1
    } else if (width < breakpoints.md) {
      return 2
    } else if (width < breakpoints.lg) {
      return 3
    } else if (width < breakpoints.xl) {
      return 5
    } else {
      return 6
    }
  }
  const [columns, setColumns] = useState(getColumns(window.innerWidth))
  const updateDimensions = () => {
    setColumns(getColumns(window.innerWidth))
  }

  useEffect(() => {
    getMovies()
  }, [type, order])

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setType('all')
    getMovies()
  }

  return (
    <>
      <Title value='Lista de Filmes' />
      <Box sx={BoxPrimaryStyle}>
        <Box sx={BoxSecundaryStyle}>
          <Paper component='form' sx={PaperStyle} onSubmit={handleSubmit}>
            <InputBase
              autoFocus
              sx={{ ml: 1, flex: 1 }}
              placeholder='Procure um filme...'
              inputProps={{ 'aria-label': 'Procure um filme...' }}
              onChange={
                (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setQuery(event.target.value)
              }
              value={query}
            />
            <IconButton type='submit' sx={{ p: '10px' }} aria-label='search' color='primary'>
              <Search />
            </IconButton>
            {
              query &&
              <IconButton
                sx={{ p: '10px' }}
                aria-label='search'
                color='default'
                onClick={() => {
                  setQuery('')
                  setType('now-playing')
                }}
              >
                <Backspace />
              </IconButton>
            }

          </Paper>

          <FormControl sx={FormControlStyle} size='small' disabled={query ? true : false}>
            <InputLabel color='primary'>Tipo</InputLabel>
            <Select
              value={type}
              label='Tipo'
              onChange={(event: SelectChangeEvent) => setType(event.target.value)}
            >
              <MenuItem value='now-playing'><FontAwesomeIcon icon={faCalendarDay} /> &nbsp; Em Cartaz</MenuItem>
              <MenuItem value='popular'><FontAwesomeIcon icon={faFireFlameCurved} /> &nbsp; Populares</MenuItem>
              <MenuItem value='upcoming'><FontAwesomeIcon icon={faCalendar} /> &nbsp; Em breve</MenuItem>
              <MenuItem value='top-rated'><FontAwesomeIcon icon={faHeart} /> &nbsp; Mais Avaliados</MenuItem>
              <MenuItem value='all'><FontAwesomeIcon icon={faA} style={{ transform: 'rotate(180deg)' }} /> &nbsp; Todos</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={FormControlStyle} size='small'>
            <InputLabel color='primary'>Ordenar</InputLabel>
            <Select
              value={order}
              label='Ordenar'
              onChange={(event: SelectChangeEvent) => setOrder(event.target.value)}
            >
              <MenuItem value='title-ASC'><FontAwesomeIcon icon={faArrowDownAZ} /> &nbsp; Titulo</MenuItem>
              <MenuItem value='title-DESC'><FontAwesomeIcon icon={faArrowDownZA} /> &nbsp; Titulo</MenuItem>
              <MenuItem value='release_date-ASC'><FontAwesomeIcon icon={faArrowDown19} /> &nbsp; Data Lançamento</MenuItem>
              <MenuItem value='release_date-DESC'><FontAwesomeIcon icon={faArrowDown91} /> &nbsp; Data Lançamento</MenuItem>
              <MenuItem value='popularity-ASC'><FontAwesomeIcon icon={faArrowDown19} /> &nbsp; Popularidade</MenuItem>
              <MenuItem value='popularity-DESC'><FontAwesomeIcon icon={faArrowDown91} /> &nbsp; Popularidade</MenuItem>
              <MenuItem value='runtime-ASC'><FontAwesomeIcon icon={faArrowDown19} /> &nbsp; Duração</MenuItem>
              <MenuItem value='runtime-DESC'><FontAwesomeIcon icon={faArrowDown91} /> &nbsp; Duração</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {
          result?.data && loading === false ?
            <ImageList gap={1} cols={columns}>
              {result.data.map((movie: Movie) => (
                <ImageListItem key={movie.id}>
                  <BoxMovie movie={movie} />
                  <Divider />
                </ImageListItem>
              ))}
            </ImageList> :
            <ImageList gap={1} cols={columns}>
              {
                Array(24).fill(null).map((value, index) =>
                  <ImageListItem key={index}>
                    <MovieListSkeleton />
                  </ImageListItem>
                )
              }
            </ImageList>
        }
        {
          result?.total === 0 ? <Typography color='red'>{query} não encontrado.</Typography> :
            <Pagination
              sx={{ mb: 2 }}
              count={result?.last_page}
              color='primary'
              onChange={(event: React.ChangeEvent<unknown>, page: number) => getMovies(page)}
            />
        }

      </Box>
      {result && <Footer />}
    </>
  )
}
