import { useState } from 'react'
import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import {
  BookmarkAdd,
  BookmarkAdded,
  Favorite,
  ThumbDown,
  ThumbUp,
} from '@mui/icons-material'
import { useMovie } from '../../context/MovieContext'
import { ToggleButtonGroupStyle } from './style'
import { movieList, removeMovieList } from '../../api'
import { StatusList } from '../../enum/StatusList'

interface Props {
  statusAtual?: string | null
  movieId: number
}

export default function ToggleListButtons({ movieId, statusAtual }: Props) {
  const { userData, changeMovieStatus } = useMovie()
  const [newStatus, setNewStatus] = useState(statusAtual)
  const handleStatus = async (status: string, id: number) => {
    setNewStatus(status)
    changeMovieStatus(id, status)
    const token = userData.token
    if (status) {
      await movieList(id, status, token)
    } else {
      await removeMovieList(id, token)
    }
  }

  return (
    <Tooltip title={!userData ? 'Faça login para adicionar o filme a uma lista' : ''} arrow enterTouchDelay={0}>
      <ToggleButtonGroup
        sx={ToggleButtonGroupStyle}
        value={newStatus}
        onChange={(event, value: string) => handleStatus(value, movieId)}
        exclusive
        aria-label='Adicionar o filme a uma lista'
        size='small'
        color='primary'
        disabled={userData ? false : true}
      >
        <ToggleButton value={StatusList.I_WANT_TO_WATCH} aria-label='Adicionar na lista para ver depois'>
          <Tooltip title='Adicionar na lista para ver depois' arrow enterTouchDelay={0}>
            <BookmarkAdd />
          </Tooltip>
        </ToggleButton>

        <ToggleButton value={StatusList.ALREADY_WATCHED} aria-label='Marcar apenas como já assisti'>
          <Tooltip title='Marcar apenas como já assisti' arrow enterTouchDelay={0}>
            <BookmarkAdded />
          </Tooltip>
        </ToggleButton>

        <ToggleButton value={StatusList.LIKED_IT} aria-label='Marcar como gostei'>
          <Tooltip title='Marcar como gostei' arrow enterTouchDelay={0}>
            <ThumbUp />
          </Tooltip>
        </ToggleButton>

        <ToggleButton value={StatusList.DID_NOT_LIKE} aria-label='Marcar como não gostei'>
          <Tooltip title='Marcar como não gostei' arrow enterTouchDelay={0}>
            <ThumbDown />
          </Tooltip>
        </ToggleButton>

        <ToggleButton value={StatusList.FAVORITE} aria-label='Marcar como um dos favoritos'>
          <Tooltip title='Marcar como um dos favoritos' arrow enterTouchDelay={0}>
            <Favorite />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Tooltip >
  )
}
