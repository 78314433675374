import { Box, Skeleton } from "@mui/material";

export default function PersonListSkeleton() {
  return (
    <Box sx={{ marginRight: '50px', marginBottom: '8px' }}>
      <Skeleton width={100} height={150} variant="rectangular" />
      <Skeleton width={100} height={20} variant="rectangular" sx={{ marginTop: '8px' }} />
      <Skeleton width={100} height={15} variant="rectangular" sx={{ marginTop: '8px' }} />
    </Box>
  )
}