export const BoxPrimaryStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexWrap: 'wrap',
  margin: '10px 0 0 0',
}
export const ParagraphStyle = {
  textAlign: 'justify',
  marginX: '50px',
  maxWidth: 500,
}
export const LogosStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  mt: 5
}
export const FooterStyle = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
}