import { ReactNode } from 'react'
import {
    Box,
    Typography,
} from '@mui/material'
import { BoxMoviesStyle, BoxStyle, TitleStyle } from './style'

interface Props {
    title: string
    children: ReactNode
}

export default function SingleLineImageList({ title, children }: Props) {
    return (
        <Box sx={BoxStyle}>
            <Typography sx={TitleStyle} variant='h5' component='h2'>
                {title}
            </Typography >
            <Box sx={BoxMoviesStyle}>
                {children}
            </Box>
        </Box >
    )
}