import { ImageList, ImageListItem, Typography } from '@mui/material'
import Title from '../../components/SEO/Title'
import Image from '../../interfaces/Image'

interface Props {
    photos: Array<Image>
    title: string
}

export default function PhotosTab({ photos, title }: Props) {
    return (
        <>
        <Title value={`${title} - Posteres`} />
            {
                photos.length !== 0 ?
                    <ImageList variant='masonry' sx={{ overflow: 'hidden' }}>
                        {photos.map((item) => (
                            <ImageListItem key={item.file_path}>
                                <img
                                    src={`https://www.themoviedb.org/t/p/original/${item.file_path}?w=248&fit=crop&auto=format`}
                                    srcSet={`https://www.themoviedb.org/t/p/original/${item.file_path}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.file_path}
                                    loading='lazy'
                                    style={{ border: '1px solid #2276d2' }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                    :
                    <Typography
                        color='red'
                        align='center'
                        marginTop='10px'
                    >
                        Nenhuma imagem até o momento.
                    </Typography>
            }
        </>
    )
}
