import { Box, Skeleton } from "@mui/material";

export default function MovieListSkeleton() {
  return (
    <Box sx={{ marginRight: '10px', marginBottom: '8px' }}>
      <Skeleton width={160} height={20} variant="rectangular" sx={{ marginTop: '8px', marginLeft: '30px' }} />
      <Skeleton width={110} height={15} variant="rectangular" sx={{ marginTop: '8px', marginLeft: '50px' }} />
      <Skeleton width={220} height={330} variant="rectangular" animation="wave" sx={{ marginTop: '8px' }} />
      <Skeleton width={200} height={40} variant="rectangular" animation="wave" sx={{ marginTop: '8px', marginLeft: '10px' }} />
    </Box>
  )
}