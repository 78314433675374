export const TitleStyle = {
  mr: 2,
  display: {
    xs: 'none',
    md: 'flex',
  },
}
export const TitleMobile = {
  flexGrow: 1,
  display: {
    xs: 'flex',
    md: 'none',
  },
}
export const LoginButton = {
  marginRight: '10px',
}
export const BoxStyle = {
  flexGrow: 1,
  display: {
    xs: 'flex',
    md: 'none',
  },
}
export const MenuStyle = {
  display: {
    xs: 'block',
    md: 'none',
  },
}
export const BoxMenuStyle = {
  flexGrow: 1,
  display: {
    xs: 'none',
    md: 'flex',
  },
}
export const ButtonMenuStyle = {
  my: 2,
  display: 'block',
  color: 'white',
}