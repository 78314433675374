import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import { BoxLinkStyle, FooterStyle, LinkStyle } from './style'

export default function Footer() {
  const year = new Date().getFullYear()
  return (
    <Box component="footer" sx={FooterStyle}>
      <Typography
        variant="subtitle1"
        align="center"
        color="white"
        component="p"
      >
        Versão Beta
      </Typography>
      <Box sx={BoxLinkStyle}>
        <Typography sx={{ ml: 2 }}>© {year}</Typography>
        <Link to='/sobre' style={LinkStyle}>Sobre</Link>
        <Link to='/faq' style={LinkStyle}>FAQ</Link>
        <Link to='/contate-nos' style={LinkStyle}>Contate-nos</Link>
      </Box>
    </Box>
  )
}