const CryptoJS = require("crypto-js")
const key = 'cf83e1357eefb8bdf1542850d66d8007d620e4050b5715dc83f4a921d36ce9ce47d0d13c5d85f2b0ff8318d2877eec2f63b931bd47417a81a538327af927da3e'

export const Crypto = {
  encrypt(data: any) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString()
  },

  decrypt(ciphertext: string) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, key)
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  }
}
