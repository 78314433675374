interface Props {
  value: number | undefined
  currency?: string
  country?: string
}

export default function Money({ value, currency = 'USD', country = 'pt-BR' }: Props) {
  const money = value !== undefined ? new Intl.NumberFormat(country, { style: 'currency', currency }).format(value) : 'Desconhecido'
  return (
    <>{money}</>
  )
}
