import { Dispatch, ReactNode, SetStateAction } from 'react'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Alert, Paper } from '@mui/material'
import { Navigate } from 'react-router-dom'
import { useMovie } from '../../context/MovieContext'
import Footer from '../../components/Footer'

interface Props {
  children: ReactNode
  title: string
  error: string | null
  setError: Dispatch<SetStateAction<string | null>>
}

export default function LoginPage({ children, title, error, setError }: Props) {
  const { userData } = useMovie()
  if (userData) {
    return <Navigate to="/" replace />
  }

  return (
    <>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(/img/login/wikinerd.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {title}
            </Typography>
            {error && <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>}
            {children}
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}
