import { Helmet } from 'react-helmet'
import Movie from '../../../interfaces/Movie'
import Person from '../../../interfaces/Person'
import Country from '../../../interfaces/Country'
import Company from '../../../interfaces/Company'

interface Props {
  data: Movie | Person
}

const movieData = (movie: Movie) => {
  const data: any = {
    "@context": "https://schema.org/",
    "@type": "Movie",
    "name": movie.title,
    "dateCreated": movie.release_date,
    "duration": movie.runtime,
    "image": `https://www.themoviedb.org/t/p/w220_and_h330_face/${movie.poster_path}`,
    "description": movie.overview,
    "inLanguage": movie.original_language,
    "countryOfOrigin": [],
    "productionCompany": [],
    "keywords": movie.keywords.map((keyword) => keyword.name).join(', '),
    "genres": movie.genres.map((genre) => genre.name).join(', '),
  }

  if (movie.vote_count > 0) {
    data.aggregateRating = {
      "@type": "AggregateRating",
      "ratingValue": movie.vote_average,
      "ratingCount": movie.vote_count,
      "bestRating": movie.vote_average,
      "reviewCount": movie.vote_count,
    }
  }

  movie.countries.map((country: Country) => data['countryOfOrigin'].push({ "@type": "Country", "name": country.name }))
  movie.companies.map((company: Company) => data['productionCompany'].push({ "@type": "Organization", "name": company.name }))

  return data
}

const personData = (person: Person) => {
  const data: any = {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": person.name,
    "jobTitle": person?.department?.name,
    "image": `https://www.themoviedb.org/t/p/w220_and_h330_face/${person?.profile_path}`,
    "birthDate": person.birthday,
    "birthPlace": person.place_of_birth,
    "deathDate": person.deathday,
    "gender": person.gender,
  }

  return data
}

export default function LdJson({ data }: Props) {
  let structuredData
  if (data instanceof Object && 'title' in data) {
    structuredData = movieData(data)
  }

  if (data instanceof Object && 'name' in data) {
    structuredData = personData(data)
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {
          JSON.stringify(structuredData)
        }
      </script>
    </Helmet>
  )
}
