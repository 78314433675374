import axios from 'axios'
import GetApi from '../interfaces/GetApi'
import User from '../interfaces/User'

const defineHeaders = (token?: string | null) => {
  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  }
}

export const api = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE
})

export const get = async ({
  term,
  currentPage = 1,
  order = {
    field: 'popularity',
    direction: 'DESC'
  }
}: GetApi) => {
  const response = await api.get(
    `/movies/${term}?page=${currentPage}&order=${order.field}&direction=${order.direction}`,
  )
  return response.data
}

export const getPeople = async ({
  term,
  currentPage = 1,
  order = {
    field: 'popularity',
    direction: 'DESC'
  },
  adult
}: GetApi) => {
  const response = await api.get(
    `/people/${term}?page=${currentPage}&order=${order.field}&direction=${order.direction}&adult=${adult}`,
  )
  return response.data
}

export const search = async ({
  term,
  currentPage = 1,
  order = {
    field: 'popularity',
    direction: 'DESC'
  },
}: GetApi) => {
  const response = await api.get(
    `/movies/search?query=${term}&page=${currentPage}&order=${order.field}&direction=${order.direction}`,
  )
  return response.data
}

export const searchPeople = async ({
  term,
  currentPage = 1,
  order = {
    field: 'popularity',
    direction: 'DESC'
  },
}: GetApi) => {
  const response = await api.get(
    `/people/search?query=${term}&page=${currentPage}&order=${order.field}&direction=${order.direction}`,
  )
  return response.data
}

export const getMovie = async (slug: string) => {
  const response = await api.get(`/movies/${slug}`)
  return response.data
}

export const getPerson = async (id: string) => {
  const response = await api.get(`/people/${id}`)
  return response.data
}

export const getMovieCollection = async (id: string) => {
  const response = await api.get(
    `/movies/collection/${id}`,
  )
  return response.data
}

export const login = async (email: string, password: string) => {
  return await api.post('/login', {
    email,
    password,
    origin: 'web'
  })
}

export const register = async ({ name, email, password }: User) => {
  return await api.post('/users', {
    name,
    email,
    password
  })
}

export const update = async ({ name, email, username, token }: User) => {
  return await api.put(
    '/users',
    {
      name,
      email,
      username,
    },
    defineHeaders(token)
  )
}
export const updatePassword = async ( password: string, passwordConfirm: string, token: string) => {
  return await api.put(
    '/users/password',
    {
      password,
      password_confirmation: passwordConfirm,
    },
    defineHeaders(token)
  )
}

export const movieList = async (movieId: number, status: string, token: string) => {
  return await api.post(
    '/users/movies',
    {
      id_movie: movieId,
      status,
    },
    defineHeaders(token)
  )
}

export const removeMovieList = async (movieId: number, token: string) => {
  return await api.delete(
    '/users/movies',
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        id_movie: movieId,
      },
    }
  )
}

export const getUserList = async ({
  term,
  currentPage = 1,
  order = {
    field: 'popularity',
    direction: 'DESC'
  },
  filter = {
    statusList: '',
  },
  token
}: GetApi) => {
  const response = await api.get(
    `/users/${term}?page=${currentPage}&order=${order.field}&direction=${order.direction}${filter.statusList !== '' ? `&status=${filter.statusList}` : ''}`,
    defineHeaders(token)
  )
  return response.data
}

export const getMovieCast = async (slug: string) => {
  const response = await api.get(
    `/movies/${slug}/cast`,
  )
  return response.data
}

export const getMovieCrew = async (slug: string) => {
  const response = await api.get(
    `/movies/${slug}/crew`,
  )
  return response.data
}

export const getPersonCast = async (slug: string) => {
  const response = await api.get(`/people/${slug}/cast`)
  return response.data
}

export const getPersonCrew = async (slug: string) => {
  const response = await api.get(`/people/${slug}/crew`)
  return response.data
}

export const getUserListStatus = async (ids: Array<number>, token: string) => {
  if (ids.length > 0) {
    const response = await api.get(`/users/movies-list`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: { ids: ids }
    })
    return response.data
  }

  return null
}
