import { Box, Typography } from '@mui/material'
import { BoxStyle, TypographyStyle } from './style'

interface Props {
    label: string
    text: any
}

export default function BoxItem({ label, text }: Props) {

    return (
        <Box
            key={label}
            sx={BoxStyle}
        >
            <Typography
                component='div'
                variant='subtitle2'
                sx={TypographyStyle}
            >
                {label}:
            </Typography>
            <Typography
                component='div'
                variant='body2'
            >
                {text}
            </Typography>
        </Box>
    )
}