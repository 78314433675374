import { getUserListStatus } from "../../api"
import Movie from "../../interfaces/Movie"
import UserListMovie from "../../interfaces/UserListMovie"

export const GetStatusMovie = async (data: any, token: string) => {
  let ids = data.map((movie: Movie) => movie.id)
  const statusResult = await getUserListStatus(ids, token)

  if (statusResult) {
    statusResult.forEach((userListMovie: UserListMovie) => {
      data.map((movie: Movie) => {
        if (movie.id === userListMovie.movie_id) {
          movie.user_status = userListMovie.status
        }

        return movie
      })
    })
  }

  return data

}
