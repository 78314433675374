import {
  Alert,
  AlertTitle,
  Box,
  CardMedia, Checkbox, Divider, Grid, List, ListItem, ListItemText, Stack, styled, Tab, Tabs, Typography
} from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import Footer from '../../components/Footer'
import Title from '../../components/SEO/Title'
import { FooterStyle } from './style'

import Money from '../../components/Money'

import ListItemIcon from '@mui/material/ListItemIcon'
import Collapse from '@mui/material/Collapse';
import CastIcon from '@mui/icons-material/ConnectedTv'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset'
import StarBorder from '@mui/icons-material/ChevronRight'
import StreamingData from './../../data/streamingData.json'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface Streaming {
  icon: string
  logo: string
  background: string
  key: string
  plans: Plan[]
}

interface Plan {
  id: string
  label: string
  price: number
  checked: boolean
  link: string
  aditional?: string
  price2?: string
}

interface Streamings extends Array<Streaming> { }

interface DivTotalProps {
  value: number
}

interface ListItemProps {
  streaming: Streaming
  handle: any
}

interface CustomListItemProps {
  streamings: Streamings
  handle: any
}

interface ShowMoneyProps {
  plan: Plan
}

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-around',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

function CustomList({ streamings, handle }: CustomListItemProps) {
  return (
    <Box>
      {
        streamings.map((streaming: Streaming) => (
          <List
            key={streaming.key}
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {
              Object.keys(streaming.plans).length > 1 ?
                <AdvancedListItem streaming={streaming} handle={handle} />
                :
                <SimpleListItem streaming={streaming} handle={handle} />
            }
            <Divider />
          </List>
        ))
      }
    </Box>
  )
}

const iconMapping: { [key: string]: React.ReactNode } = {
  'tv': <CastIcon />,
  'music': <MusicNoteIcon />,
  'game': <VideogameAssetIcon />,
  'others': <SubscriptionsIcon />,
};

function AdvancedListItem({ streaming, handle }: ListItemProps) {
  return (
    <>
      <ListItem>
        <ListItemIcon>
          {iconMapping[streaming.icon]}
        </ListItemIcon>
        <ListItemText primary={
          streaming.logo ?
            <CardMedia
              component="img"
              sx={{ width: '60px', backgroundColor: streaming.background }}
              image={streaming.logo}
              alt={streaming.key}
            />
            :
            <Typography variant="h6" component="h6" >
              {streaming.key}
            </Typography>
        } />
      </ListItem>
      <Collapse in={true} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            streaming.plans.map((plan: Plan) => {
              return <ListItem
                key={plan.id}
                sx={{ pl: 4 }}
                secondaryAction={
                  <Checkbox checked={plan.checked} onChange={(event) => handle(event, plan, streaming.key)} />
                }>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary={plan.label} secondary={<ShowMoney plan={plan} />} />
              </ListItem>
            })
          }
        </List>
      </Collapse>
    </>
  )
}

function SimpleListItem({ streaming, handle }: ListItemProps) {
  return (
    <>
      {
        streaming.plans.map(
          (plan: Plan) => <ListItem
            key={plan.id}
            secondaryAction={
              <Checkbox checked={plan.checked} onChange={(event) => handle(event, plan, streaming.key)} />
            }>
            <ListItemIcon>
              {iconMapping[streaming.icon]}
            </ListItemIcon>
            <ListItemText
              primary={
                streaming.logo ?
                  <CardMedia
                    component="img"
                    sx={{ width: '60px', backgroundColor: streaming.background }}
                    image={streaming.logo}
                    alt={streaming.key}
                  />
                  :
                  <Typography variant="h6" component="h6" >
                    {streaming.key}
                  </Typography>
              }
              secondary={<ShowMoney plan={plan} />} />
          </ListItem>
        )
      }
    </>

  )
}

function ShowMoney({ plan }: ShowMoneyProps) {
  return (
    <>
      <Money value={plan.price} currency='BRL' />
      {plan.price2 && <>{plan.price2} </>}
      {plan.link && <a style={{ marginLeft: '40px' }} href={plan.link} target='_blank' rel="noreferrer">Saiba Mais</a>}
    </>
  )
}

function DivTotal({ value }: DivTotalProps) {
  return (
    <Typography
      component='span'
      sx={{
        backgroundColor: '#673ab7',
        color: 'white',
        fontSize: 'x-large',
        padding: '10px',
        margin: '5px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      TOTAL: <Money value={value} currency='BRL' />
    </Typography>
  )
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CalcStreamingPage() {
  const pageTitle = 'Selecione os streamings'

  const [totalMensal, setTotalMensal] = useState(0)
  const [totalAnual, setTotalAnual] = useState(0)
  const [totalTrimestral, setTotalTrimestral] = useState(0)

  const [streamingMensal, setStreamingMensal] = useState<Streamings>(StreamingData.mensal)
  const [streamingAnual, setStreamingAnual] = useState<Streamings>(StreamingData.anual)
  const [streamingTrimestral, setStreamingTrimestral] = useState<Streamings>(StreamingData.trimestral)

  const lastUpdateDate = '30/10/2023'

  const handleChangeMensal = (event: React.ChangeEvent<HTMLInputElement>, planData: Plan, key: string) => {
    let newData = streamingMensal.map((streaming: Streaming) => {
      if (streaming.key === key) {
        streaming.plans.map((plan: Plan) => {
          if (key !== 'Combos' && key !== 'Globoplay' && key !== 'Amazon Prime Video') {
            plan.checked = false
          }
          planData.checked = event.target.checked

          if (plan.id === planData.id) {
            return planData
          }

          return plan
        })
      }

      return streaming
    })

    switch (key) {
      case 'Disney+':
        newData = disablePlan('Combos', 'combo-disney-star', streamingMensal)
        newData = disablePlan('Combos', 'combo-disney-star-lionsgate', streamingMensal)
        newData = disablePlan('Combos', 'combo-globoplay-disney-plus', streamingMensal)
        newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-disney-star-plus', streamingMensal)
        break;
      case 'Star+':
        newData = disablePlan('Combos', 'combo-disney-star', streamingMensal)
        newData = disablePlan('Combos', 'combo-disney-star-lionsgate', streamingMensal)
        break;
      case 'Amazon Prime Video':
        if (planData.id === 'lionsgate-plus') {
          newData = disablePlan('Combos', 'combo-disney-star-lionsgate', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-lionsgate-plus', streamingMensal)
        }
        if (planData.id === 'premiere') {
          newData = disablePlan('Combos', 'combo-telecine-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-premiere', streamingMensal)
        }
        if (planData.id === 'discovery-plus') {
          newData = disablePlan('Combos', 'combo-globoplay-discovery-plus', streamingMensal)
        }
        break;
      case 'Globoplay':
        if (planData.id === 'globoplay') {
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-disney-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-lionsgate-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-discovery-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-disney-star-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-premiere', streamingMensal)
        }
        if (planData.id === 'telecine') {
          newData = disablePlan('Combos', 'combo-telecine-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-telecine', streamingMensal)
        }
        break;
      case 'Combos':
        if (planData.id === 'combo-disney-star') {
          newData = disablePlan('Disney+', 'disney-plus', streamingMensal)
          newData = disablePlan('Star+', 'star-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-disney-star-lionsgate', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-disney-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-disney-star-plus', streamingMensal)
        }
        if (planData.id === 'combo-disney-star-lionsgate') {
          newData = disablePlan('Disney+', 'disney-plus', streamingMensal)
          newData = disablePlan('Star+', 'star-plus', streamingMensal)
          newData = disablePlan('Amazon Prime Video', 'lionsgate-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-disney-star', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-disney-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-lionsgate-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-disney-star-plus', streamingMensal)
        }
        if (planData.id === 'combo-globoplay-canais-ao-vivo') {
          newData = disablePlan('Globoplay', 'globoplay', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-disney-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-lionsgate-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-discovery-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-disney-star-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-premiere', streamingMensal)
        }
        if (planData.id === 'combo-globoplay-disney-plus') {
          newData = disablePlan('Globoplay', 'globoplay', streamingMensal)
          newData = disablePlan('Disney+', 'disney-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-disney-star', streamingMensal)
          newData = disablePlan('Combos', 'combo-disney-star-lionsgate', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-lionsgate-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-discovery-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-disney-star-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-premiere', streamingMensal)
        }
        if (planData.id === 'combo-globoplay-telecine') {
          newData = disablePlan('Globoplay', 'globoplay', streamingMensal)
          newData = disablePlan('Globoplay', 'telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-disney-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-lionsgate-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-discovery-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-disney-star-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-telecine-premiere', streamingMensal)
        }
        if (planData.id === 'combo-globoplay-lionsgate-plus') {
          newData = disablePlan('Globoplay', 'globoplay', streamingMensal)
          newData = disablePlan('Amazon Prime Video', 'lionsgate-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-disney-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-discovery-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-disney-star-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-disney-star-lionsgate', streamingMensal)
        }
        if (planData.id === 'combo-globoplay-premiere') {
          newData = disablePlan('Globoplay', 'globoplay', streamingMensal)
          newData = disablePlan('Amazon Prime Video', 'premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-disney-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-lionsgate-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-discovery-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-disney-star-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-telecine-premiere', streamingMensal)
        }
        if (planData.id === 'combo-globoplay-discovery-plus') {
          newData = disablePlan('Globoplay', 'globoplay', streamingMensal)
          newData = disablePlan('Amazon Prime Video', 'discovery-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-disney-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-lionsgate-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-disney-star-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-premiere', streamingMensal)
        }
        if (planData.id === 'combo-globoplay-canais-ao-vivo-disney-star-plus') {
          newData = disablePlan('Globoplay', 'globoplay', streamingMensal)
          newData = disablePlan('Disney+', 'disney-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-disney-star', streamingMensal)
          newData = disablePlan('Combos', 'combo-disney-star-lionsgate', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-disney-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-lionsgate-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-discovery-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-premiere', streamingMensal)
        }
        if (planData.id === 'combo-globoplay-canais-ao-vivo-telecine') {
          newData = disablePlan('Globoplay', 'globoplay', streamingMensal)
          newData = disablePlan('Globoplay', 'telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-disney-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-lionsgate-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-discovery-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-disney-star-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-premiere', streamingMensal)
        }
        if (planData.id === 'combo-globoplay-canais-ao-vivo-premiere') {
          newData = disablePlan('Globoplay', 'globoplay', streamingMensal)
          newData = disablePlan('Amazon Prime Video', 'premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-disney-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-lionsgate-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-discovery-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-disney-star-plus', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-telecine-premiere', streamingMensal)
        }
        if (planData.id === 'combo-telecine-premiere') {
          newData = disablePlan('Globoplay', 'telecine', streamingMensal)
          newData = disablePlan('Amazon Prime Video', 'premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-premiere', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-telecine', streamingMensal)
          newData = disablePlan('Combos', 'combo-globoplay-canais-ao-vivo-premiere', streamingMensal)
        }
        break;
    }

    setStreamingMensal(newData)
    calcTotal(streamingMensal, setTotalMensal)
  }

  const handleChangeAnual = (event: React.ChangeEvent<HTMLInputElement>, planData: Plan, key: string) => {
    let newData = streamingAnual.map((streaming: Streaming) => {
      if (streaming.key === key) {
        streaming.plans.map((plan: Plan) => {
          if (key !== 'Combos Anual' && key !== 'Globoplay Anual' && key !== 'Amazon Prime Video Anual') {
            plan.checked = false
          }
          planData.checked = event.target.checked

          if (plan.id === planData.id) {
            return planData
          }

          return plan
        })
      }

      return streaming
    })

    switch (key) {
      case 'Disney+ Anual':
        newData = disablePlan('Combos Anual', 'combo-globoplay-disney-plus-anual', streamingAnual)
        newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-disney-star-plus-anual', streamingAnual)
        break;
      case 'Amazon Prime Video Anual':
        if (planData.id === 'premiere-anual') {
          newData = disablePlan('Combos Anual', 'combo-telecine-premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-premiere-anual', streamingAnual)
        }
        break;
      case 'Globoplay Anual':
        if (planData.id === 'globoplay-anual') {
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-disney-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-discovery-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-disney-star-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-premiere-anual', streamingAnual)
        }
        if (planData.id === 'telecine-anual') {
          newData = disablePlan('Combos Anual', 'combo-telecine-premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-telecine-anual', streamingAnual)
        }
        break;
      case 'Combos Anual':
        if (planData.id === 'combo-globoplay-canais-ao-vivo-anual') {
          newData = disablePlan('Globoplay Anual', 'globoplay-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-disney-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-discovery-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-disney-star-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-premiere-anual', streamingAnual)
        }
        if (planData.id === 'combo-globoplay-disney-plus-anual') {
          newData = disablePlan('Globoplay Anual', 'globoplay-anual-anual', streamingAnual)
          newData = disablePlan('Disney+ Anual', 'disney-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-discovery-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-disney-star-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-premiere-anual', streamingAnual)
        }
        if (planData.id === 'combo-globoplay-telecine-anual') {
          newData = disablePlan('Globoplay Anual', 'globoplay-anual-anual', streamingAnual)
          newData = disablePlan('Globoplay Anual', 'telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-disney-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-discovery-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-disney-star-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-telecine-premiere-anual', streamingAnual)
        }
        if (planData.id === 'combo-globoplay-premiere-anual') {
          newData = disablePlan('Globoplay Anual', 'globoplay-anual-anual', streamingAnual)
          newData = disablePlan('Amazon Prime Video Anual', 'premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-disney-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-discovery-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-disney-star-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-telecine-premiere-anual', streamingAnual)
        }
        if (planData.id === 'combo-globoplay-discovery-plus-anual') {
          newData = disablePlan('Globoplay Anual', 'globoplay-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-disney-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-lionsgate-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-disney-star-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-premiere-anual', streamingAnual)
        }
        if (planData.id === 'combo-globoplay-canais-ao-vivo-disney-star-plus-anual') {
          newData = disablePlan('Globoplay Anual', 'globoplay-anual-anual', streamingAnual)
          newData = disablePlan('Disney+ Anual', 'disney-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-disney-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-discovery-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-premiere-anual', streamingAnual)
        }
        if (planData.id === 'combo-globoplay-canais-ao-vivo-telecine-anual') {
          newData = disablePlan('Globoplay Anual', 'globoplay-anual-anual', streamingAnual)
          newData = disablePlan('Globoplay Anual', 'telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-disney-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-discovery-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-disney-star-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-premiere-anual', streamingAnual)
        }
        if (planData.id === 'combo-globoplay-canais-ao-vivo-premiere-anual') {
          newData = disablePlan('Globoplay Anual', 'globoplay-anual-anual', streamingAnual)
          newData = disablePlan('Amazon Prime Video Anual', 'premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-disney-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-discovery-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-disney-star-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-telecine-premiere-anual', streamingAnual)
        }
        if (planData.id === 'combo-telecine-premiere-anual') {
          newData = disablePlan('Globoplay Anual', 'telecine-anual', streamingAnual)
          newData = disablePlan('Amazon Prime Video Anual', 'premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-premiere-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-discovery-plus-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-telecine-anual', streamingAnual)
          newData = disablePlan('Combos Anual', 'combo-globoplay-canais-ao-vivo-premiere-anual', streamingAnual)
        }
        break;
    }

    setStreamingAnual(newData)
    calcTotal(streamingAnual, setTotalAnual)
  }

  const handleChangeTrimestral = (event: React.ChangeEvent<HTMLInputElement>, planData: Plan, key: string) => {
    let newData = streamingTrimestral.map((streaming: Streaming) => {
      if (streaming.key === key) {
        streaming.plans.map((plan: Plan) => {
          if (key !== 'Combos') {
            plan.checked = false
          }
          planData.checked = event.target.checked

          if (plan.id === planData.id) {
            return planData
          }

          return plan
        })
      }

      return streaming
    })

    setStreamingTrimestral(newData)
    calcTotal(streamingTrimestral, setTotalTrimestral)
  }

  function calcTotal(streamings: Streamings, setState: Dispatch<SetStateAction<number>>) {
    let newValue = 0
    streamings.map((streaming: Streaming) => {
      const result = streaming.plans.map((plan: Plan) => {
        if (plan.checked) {
          return plan.price
        }

        return 0
      })

      const reduce = result.reduce((total: number, num: number) => total + num, 0)
      newValue = newValue + reduce

      return reduce
    })

    setState(newValue)
  }

  function disablePlan(key: string, id: string, streaming: Streamings) {
    return streaming.map((streaming: Streaming) => {
      if (streaming.key === key) {
        streaming.plans.map((plan: Plan) => {
          if (plan.id === id) {
            plan.checked = false
          }

          return plan
        })
      }
      return streaming
    })
  }

  const [value, setValue] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Title value={pageTitle} />

      <Box sx={{ flexGrow: 1, marginTop: '5px', marginBottom: '25px' }}>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChangeTab} aria-label="Tipos de planos" centered>
                  <Tab label="Mensal" {...a11yProps(0)} />
                  <Tab label="Anual" {...a11yProps(1)} />
                  <Tab label="Trimestral" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <DivTotal value={totalMensal} />
                <Root>
                  <CustomList streamings={streamingMensal.filter(streaming => streaming.key !== 'Combos')} handle={handleChangeMensal} />
                  <CustomList streamings={streamingMensal.filter(streaming => streaming.key === 'Combos')} handle={handleChangeMensal} />
                </Root>
                <DivTotal value={totalMensal} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <DivTotal value={totalAnual} />
                <Root>
                  <CustomList streamings={streamingAnual.filter(streaming => streaming.key !== 'Combos Anual')} handle={handleChangeAnual} />
                  <CustomList streamings={streamingAnual.filter(streaming => streaming.key === 'Combos Anual')} handle={handleChangeAnual} />
                </Root>
                <DivTotal value={totalAnual} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <DivTotal value={totalTrimestral} />
                <CustomList streamings={streamingTrimestral} handle={handleChangeTrimestral} />
              </TabPanel>
            </Box>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Stack sx={{ margin: '10px' }} spacing={2}>
              <Alert severity="warning">
                <AlertTitle>Atenção</AlertTitle>
                <Typography>Esta NÃO é uma ferramenta oficial de nenhum streaming, todos os dados aqui fornecidos são coletados diretamente no site de cada plataforma, podendo assim estar desatualizado no momento que for efetuar a assinatura. </Typography>
                <Typography>Caso perceba alguma inconsistência, por favor nos informe que efetuaremos a atualização.</Typography>
                <Typography>Ultima atualização: {lastUpdateDate}. </Typography>
              </Alert>
              <Alert severity="info">
                <AlertTitle>Dica</AlertTitle>
                <Typography>Para aqueles que alcançaram o Nível 6 no Meli+ (Mercado Livre), boas notícias! Você pode aproveitar Disney+ e Star+ gratuitamente, além de obter um desconto de 30% no HBO Max (saindo por R$ 24,43) e 30% de desconto na Paramount+ (saindo por R$ 13,93). Além disso, você pode desfrutar do Deezer Premium gratuitamente por 12 meses. Para mais detalhes, confira o site do <a href='https://www.mercadolivre.com.br/assinaturas/melimais' target='_blank' rel="noreferrer">Mercado Livre</a>.
                </Typography>
              </Alert>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box sx={FooterStyle}>
        <Footer />
      </Box>
    </>
  )
}
