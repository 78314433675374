export const BoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 0 10px 0'
}
export const TypographyStyle = {
  whiteSpace: 'nowrap',
  width: '238px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}
export const BoxImageStyle = {
  borderRadius: '10px',
  margin: '5px',
  height: '330px',
  width: '220px',
  cursor: 'pointer',
  '&:hover': {
    filter: 'opacity(0.9)',
  },
}
export const BoxImageNotLoadedStyle = {
  borderRadius: '10px',
  margin: '5px',
  height: '330px',
  width: '220px',
  cursor: 'pointer',
}