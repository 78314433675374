import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
import HomePage from './pages/HomePage'
import Header from './components/Header'
import { MovieProvider } from './context/MovieContext'
import LoginPage from './pages/Unauthenticated/LoginPage'
import RegisterPage from './pages/Unauthenticated/RegisterPage'
import { createTheme, ThemeProvider } from '@mui/material'
import { deepPurple } from '@mui/material/colors'
import MoviePage from './pages/MoviePage'
import PeoplePage from './pages/PeoplePage'
import PersonPage from './pages/PersonPage'
import MoviesPage from './pages/MoviesPage'
import ProfilePage from './pages/ProfilePage'
import AboutPage from './pages/AboutPage'
import FaqPage from './pages/FaqPage'
import ContactUsPage from './pages/ContactUsPage'
import AccountPage from './pages/AccountPage'
import TagManager from 'react-gtm-module'
import CalcStreamingPage from './pages/CalcStreamingPage'

const tagManagerArgs = {
  gtmId: 'GTM-T3BSS79'
}
TagManager.initialize(tagManagerArgs)

export default function App() {
  const theme = createTheme({
    palette: {
      primary: deepPurple,
    },
  })
  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <MovieProvider>
            <Header />
            <Routes>
              <Route path='/' element={<HomePage />} />
              <Route path='/perfil' element={<ProfilePage />} />
              <Route path='/conta' element={<AccountPage />} />
              <Route path='/filmes' element={<MoviesPage />} />
              <Route path='/filmes/:path' element={<MoviePage />} />
              <Route path='/personalidades' element={<PeoplePage />} />
              <Route path='/personalidades/:path' element={<PersonPage />} />
              <Route path='/sobre' element={<AboutPage />} />
              <Route path='/faq' element={<FaqPage />} />
              <Route path='/contate-nos' element={<ContactUsPage />} />
              <Route path='/login' element={<LoginPage />} />
              <Route path='/registrar' element={<RegisterPage />} />
              <Route path='/calculadora-streaming' element={<CalcStreamingPage />} />
              <Route path='*' element={<PageNotFound />} />
            </Routes>
          </MovieProvider>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  )
}

function PageNotFound() {
  return (
    <div>
      <h2>404 Page not found</h2>
    </div>
  );
}
