/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  FormControl,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { getUserList } from '../../api'
import BoxMovie from '../../components/BoxMovie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDownAZ,
  faArrowDownZA,
  faArrowDown19,
  faArrowDown91,
  faA,
} from '@fortawesome/free-solid-svg-icons'
import { BoxPrimaryStyle, BoxSecundaryStyle, FormControlStyle } from './style'
import { useMovie } from '../../context/MovieContext'
import GetApi from '../../interfaces/GetApi'
import { BookmarkAdd, BookmarkAdded, Favorite, ThumbDown, ThumbUp } from '@mui/icons-material'
import { StatusList } from '../../enum/StatusList'
import Footer from '../../components/Footer'
import MovieListSkeleton from '../../components/MovieListSkeleton'
import { Navigate } from 'react-router-dom'
import Title from '../../components/SEO/Title'
import ReturnUserList from '../../interfaces/ReturnUserList'

export default function ProfilePage() {

  const pageTitle = 'Perfil'

  const [result, setResults] = useState<ReturnUserList>()
  const [statusList, setStatusList] = useState<string>('I want to watch')
  const [order, setOrder] = useState<string>('popularity-DESC')
  const { userData } = useMovie()
  const [loading, setLoading] = useState<boolean>(true)

  const getMovies = async (currentPage: number = 1) => {
    setLoading(true)
    const [field, direction] = order.split('-')
    const getMovies: GetApi = {
      term: 'movies',
      currentPage,
      order: {
        field,
        direction
      },
      filter: {
        statusList: statusList !== 'all' ? statusList : '',
      },
      token: userData.token,
      adult: false
    }
    let response = await getUserList(getMovies)
    setResults(response)
    setLoading(false)
  }
  const breakpoints = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
  const getColumns = (width: any) => {
    if (width < breakpoints.sm) {
      return 1
    } else if (width < breakpoints.md) {
      return 2
    } else if (width < breakpoints.lg) {
      return 3
    } else if (width < breakpoints.xl) {
      return 4
    } else {
      return 5
    }
  }
  const [columns, setColumns] = useState(getColumns(window.innerWidth))
  const updateDimensions = () => {
    setColumns(getColumns(window.innerWidth))
  }

  useEffect(() => {
    getMovies()
  }, [statusList, order])

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [])

  if (!userData) {
    return <Navigate to="/login" replace />
  }

  return (
    <>
      <Title value={pageTitle} />
      <Box sx={BoxPrimaryStyle}>
        <Box sx={BoxSecundaryStyle}>
          <FormControl sx={FormControlStyle} size='small'>
            <InputLabel color='primary'>Lista</InputLabel>
            <Select
              value={statusList}
              label='Lista'
              onChange={(event: SelectChangeEvent) => setStatusList(event.target.value)}
            >
              <MenuItem value='all'><FontAwesomeIcon icon={faA} style={{ transform: 'rotate(180deg)' }} /> &nbsp; Todos</MenuItem>
              <MenuItem value={StatusList.I_WANT_TO_WATCH}><BookmarkAdd fontSize='small' /> &nbsp; Quero Assistir</MenuItem>
              <MenuItem value={StatusList.ALREADY_WATCHED}><BookmarkAdded fontSize='small' /> &nbsp; Já Assisti</MenuItem>
              <MenuItem value={StatusList.LIKED_IT}><ThumbUp fontSize='small' /> &nbsp; Gostei</MenuItem>
              <MenuItem value={StatusList.DID_NOT_LIKE}><ThumbDown fontSize='small' /> &nbsp; Não Gostei</MenuItem>
              <MenuItem value={StatusList.FAVORITE}><Favorite fontSize='small' /> &nbsp; Favoritos</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={FormControlStyle} size='small' disabled={result && result?.last_page === 0 ? true : false}>
            <InputLabel color='primary'>Ordenar</InputLabel>
            <Select
              value={order}
              label='Ordenar'
              onChange={(event: SelectChangeEvent) => setOrder(event.target.value)}
            >
              <MenuItem value='title-ASC'><FontAwesomeIcon icon={faArrowDownAZ} /> &nbsp; Titulo</MenuItem>
              <MenuItem value='title-DESC'><FontAwesomeIcon icon={faArrowDownZA} /> &nbsp; Titulo</MenuItem>
              <MenuItem value='release_date-ASC'><FontAwesomeIcon icon={faArrowDown19} /> &nbsp; Data Lançamento</MenuItem>
              <MenuItem value='release_date-DESC'><FontAwesomeIcon icon={faArrowDown91} /> &nbsp; Data Lançamento</MenuItem>
              <MenuItem value='popularity-ASC'><FontAwesomeIcon icon={faArrowDown19} /> &nbsp; Popularidade</MenuItem>
              <MenuItem value='popularity-DESC'><FontAwesomeIcon icon={faArrowDown91} /> &nbsp; Popularidade</MenuItem>
              <MenuItem value='runtime-ASC'><FontAwesomeIcon icon={faArrowDown19} /> &nbsp; Duração</MenuItem>
              <MenuItem value='runtime-DESC'><FontAwesomeIcon icon={faArrowDown91} /> &nbsp; Duração</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {
          result?.data && loading === false ?
            <ImageList gap={1} cols={columns}>
              {
                result.data.map((data) => {
                  const movie = {
                    id: data.id,
                    title: data.title,
                    slug: data.slug,
                    release_date: data.release_date,
                    poster_path: data.poster_path,
                    user_status: data.user_status
                  }
                  return (
                    <ImageListItem key={data.id}>
                      <BoxMovie movie={movie} />
                      <Divider />
                    </ImageListItem>
                  )
                })
              }
            </ImageList> :
            <ImageList gap={1} cols={columns}>
              {
                Array(10).fill(null).map((value, index) =>
                  <ImageListItem key={index}>
                    <MovieListSkeleton />
                  </ImageListItem>
                )
              }
            </ImageList>
        }
        {
          result?.total === 0 ? <Typography color='red'>Você ainda não adicionou nenhum filme a lista.</Typography> :
            <Pagination
              sx={{ mb: 2 }}
              count={result?.last_page}
              color='primary'
              onChange={(event: React.ChangeEvent<unknown>, page: number) => getMovies(page)}
            />
        }
      </Box>
      {result && <Footer />}
    </>
  )
}
