import { FormEvent, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Link, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import BasePage from './BasePage'
import { login } from '../../api'
import { useMovie } from '../../context/MovieContext'

export default function LoginPage() {
  const [error, setError] = useState<string | null>(null)
  const { storeUserData } = useMovie()
  const navigate = useNavigate()

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email')
    const password = data.get('password')
    if (typeof email === 'string' && typeof password === 'string') {
      login(email, password)
        .then((response) => {
          const user = response.data
          storeUserData(user)
          navigate('/')
        })
        .catch(err => setError(err.response.data.message))
    }
  }

  return (
    <BasePage title='Entrar' error={error} setError={setError}>
      <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          margin='normal'
          required
          fullWidth
          id='email'
          label='Email'
          name='email'
          autoComplete='email'
          autoFocus
        />
        <TextField
          margin='normal'
          required
          fullWidth
          name='password'
          label='Senha'
          type='password'
          id='password'
          autoComplete='current-password'
        />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
        >
          Entrar
        </Button>
        <Grid container>
          <Grid item xs>
            <Button variant='text'>
              Esqueceu a senha?
            </Button>
          </Grid>
          <Grid item>
            <Button variant='text' component={Link} to='/registrar'>
              Não tem uma conta? Inscrever-se
            </Button>
          </Grid>
        </Grid>
      </Box>
    </BasePage>
  )
}
