/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { getPerson, getPersonCast, getPersonCrew } from '../../api'
import Person from '../../interfaces/Person'
import { Box } from '@mui/system'
import { Ballot, ExpandMore } from '@mui/icons-material'
import BoxItem from '../../components/BoxItem'
import Moment from 'react-moment'
import SingleLineImageList from '../../components/SingleLineImageList'
import { BasicMovie } from '../../interfaces/Movie'
import BoxMovie from '../../components/BoxMovie'
import Cast from '../../interfaces/Cast'
import Crew from '../../interfaces/Crew'
import Footer from '../../components/Footer'
import MovieListSkeleton from '../../components/MovieListSkeleton'
import Title from '../../components/SEO/Title'
import FacebookOpenGraph from '../../components/SEO/FacebookOpenGraph'
import LdJson from '../../components/SEO/LdJson'
import { useMovie } from '../../context/MovieContext'
import { GetStatusMovie } from '../../helpers/GetStatusMovie'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

export default function PersonPage() {
  let { path } = useParams()
  const [person, setPerson] = useState<Person | null>(null)
  const [cast, setCast] = useState<Cast[]>()
  const [castIsLoading, setCastIsLoading] = useState<boolean>(true)
  const [crew, setCrew] = useState<Crew[]>()
  const [crewIsLoading, setCrewIsLoading] = useState<boolean>(true)
  const [movies, setMovies] = useState<BasicMovie[]>([])
  const [moviesIsLoading, setMoviesIsLoading] = useState<boolean>(true)
  const { userData } = useMovie()

  const extractMovie = (data: [], movies: BasicMovie[]) => {
    data.forEach((element: Cast | Crew) => {
      if (element.movie && !movies.find(el => element.movie && el.id === element?.movie.id)) {
        movies.push(element.movie)
      }
    })
  }

  useEffect(() => {
    (async () => {
      if (path) {
        const result = await getPerson(path)
        setPerson(result)

        let movies: BasicMovie[] = []
        const castResult = await getPersonCast(path)
        setCast(castResult)
        extractMovie(castResult, movies)
        setCastIsLoading(false)

        const crewResult = await getPersonCrew(path)
        setCrew(crewResult)
        extractMovie(crewResult, movies)
        setCrewIsLoading(false)

        if (userData.token && movies.length !== 0) {
          movies = await GetStatusMovie(movies, userData.token)
        }

        setMovies(movies)
        setMoviesIsLoading(false)
      }
    })()
  }, [path])
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, value: number) => {
    setValue(value)
  }

  const newCrew: any = []
  // eslint-disable-next-line array-callback-return
  crew && crew.map(element => {
    const find = newCrew.find((crew: Crew) => crew.movie?.id === element.movie?.id)
    if (find) {
      find.jobs.push(element.job.name)
    } else {
      newCrew.push({
        id: element.id,
        movie: element.movie,
        jobs: [element.job.name]
      })
    }
  })

  return (
    <>
      {
        person &&
        <>
          <Title value={person.name} />
          <FacebookOpenGraph title={person.name} id={person.id} slug={person.slug} imagePath={person.profile_path} type='person' />
          <LdJson data={person} />
        </>
      }
      <Tabs centered value={value} onChange={handleChange} aria-label='icon label tabs example'>
        <Tab
          icon={<Ballot />}
          label='SOBRE'
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              lg: 'row',
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {
              person ?
                <Box
                  sx={{
                    height: ['250px', '200px'],
                    width: ['183,33px', '133.33px'],
                    margin: '2px'
                  }}
                  component='img'
                  alt={`Foto de ${person?.name}`}
                  src={`https://www.themoviedb.org/t/p/w220_and_h330_face/${person?.profile_path}`}
                /> :
                <Skeleton width={133.33} height={200} variant="rectangular" animation="wave" sx={{ margin: '2px' }} />
            }
          </Box>
          <CardContent sx={{ width: '100%' }}>
            {
              person ?
                <>
                  <BoxItem label='Nome' text={person?.name} />
                  <BoxItem label='Conhecido(a) por' text={person?.department?.name} />
                  <BoxItem label='Data de nascimento' text={person?.birthday && <Moment format='DD/MM/YYYY'>{person.birthday}</Moment>} />
                  {person?.deathday && <BoxItem label='Data de falecimento' text={<Moment format='DD/MM/YYYY'>{person.deathday}</Moment>} />}
                  <BoxItem label='Biografia' text={person?.biography} />
                </> :
                Array(4).fill(null).map((_, index) => <Skeleton key={index} width={600} height={20} variant="rectangular" sx={{ marginTop: '4px' }} />)
            }
            <Box sx={{ width: '30%' }}>
              {
                castIsLoading ?
                  <Skeleton width={384} height={48} variant="rectangular" sx={{ marginTop: '2px' }} /> :
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Personagens</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List dense={true}>
                        {
                          cast && cast?.length !== 0 ?
                            cast.map((cast: any) =>
                              <ListItem key={Math.random()}>
                                <ListItemText
                                  primary={cast.character}
                                  secondary={<Link
                                    key={Math.random()}
                                    to={`/filmes/${cast.movie?.slug}`}
                                    style={{ textDecoration: 'none', color: 'blue' }}
                                  >
                                    {cast.movie?.title}
                                  </Link>}
                                />
                              </ListItem>
                            ) :
                            <ListItemText primary='Não Encontrado' />
                        }
                      </List>
                    </AccordionDetails>
                  </Accordion>
              }

              {
                crewIsLoading ?
                  <Skeleton width={384} height={48} variant="rectangular" sx={{ marginTop: '2px' }} /> :
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Créditos</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List dense={true}>
                        {
                          newCrew.length !== 0 ?
                            newCrew.map((crew: any) =>
                              <ListItem key={crew.id}>
                                <ListItemText
                                  primary={
                                    <Link
                                      key={crew.id}
                                      to={`/filmes/${crew.movie?.slug}`}
                                      style={{ textDecoration: 'none', color: 'blue' }}
                                    >
                                      {crew.movie?.title}
                                    </Link>
                                  }
                                  secondary={crew.jobs.map((job: string) => job).join(', ')}
                                />
                              </ListItem>
                            ) :
                            <ListItemText primary='Não Encontrado' />
                        }
                      </List>
                    </AccordionDetails>
                  </Accordion>
              }
            </Box>
          </CardContent>
        </Card>
        {
          moviesIsLoading ?
            Array(1).fill(null).map((_, index) =>
              <SingleLineImageList title='' key={index}>
                {
                  Array(6).fill(null).map((_, index) =>
                    <MovieListSkeleton key={index} />
                  )
                }
              </SingleLineImageList>
            ) :
            <SingleLineImageList title='Filmografia'>
              {movies && movies.length !== 0 && movies.map((movie: BasicMovie) => movie && <BoxMovie key={movie?.id} movie={movie} />)}
            </SingleLineImageList>
        }
      </TabPanel>
      {person && <Footer />}
    </>
  )
}
