import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import Moment from 'react-moment'
import { useNavigate } from 'react-router-dom'
import Movie, { BasicMovie } from '../../interfaces/Movie'
import ToggleListButtons from '../ToggleListButtons'
import { BoxImageNotLoadedStyle, BoxImageStyle, BoxStyle, TypographyStyle } from './style'

interface Props {
  movie: Movie | BasicMovie
}

export default function BoxMovie({ movie }: Props) {
  const navigate = useNavigate()

  const [load, setLoad] = useState<boolean>(false)
  const stylePoster = load ? BoxImageStyle : { display: 'none' }
  const status = 'user_status' in movie ? movie.user_status : null

  return (
    <Box key={movie.id} sx={BoxStyle}>
      <Typography
        sx={TypographyStyle}
        component='span'
        variant='subtitle1'
        align='center'
      >
        {movie.title}
      </Typography>
      <Typography
        component='span'
        variant='subtitle2'
      >
        <Moment format='DD/MM/YYYY'>
          {movie.release_date}
        </Moment>
      </Typography>
      {
        !load &&
        <Box
          sx={BoxImageNotLoadedStyle}
          key={movie.slug}
          component='img'
          src='./../img/not_loaded.png'
          alt={`Poster do filme ${movie.title}`}
          title={`Poster do filme ${movie.title} não carregou`}
          width='120px'
          onClick={() => navigate(`/filmes/${movie.slug}`)}
        />
      }

      <Box
        sx={stylePoster}
        key={movie.id}
        component='img'
        alt={`Poster do filme ${movie.title}`}
        src={`https://www.themoviedb.org/t/p/w220_and_h330_face/${movie.poster_path}`}
        onClick={() => navigate(`/filmes/${movie.slug}`)}
        onLoad={() => setLoad(true)}
      />
      <ToggleListButtons movieId={movie.id} statusAtual={status} />
    </Box >
  )
}
