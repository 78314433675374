import { Box, Typography } from '@mui/material'
import Link from '@mui/material/Link'
import Footer from '../../components/Footer'
import Title from '../../components/SEO/Title'
import { FooterStyle } from './style'

export default function ContactUsPage() {

  const pageTitle = 'Contate-nos'

  return (
    <>
      <Title value={pageTitle} />
      <Typography variant="h3" component="h1" textAlign='center'>
        {pageTitle}
      </Typography>
      <Typography variant="body1" textAlign='center'>
        Email: contato@wikinerd.com.br
      </Typography>
      <Typography variant="body1" textAlign='center'>
        Instagram: <Link sx={{ ml: 2 }} color="inherit" href="https://instagram.com/thewikinerd">@TheWikiNerd</Link>
      </Typography>
      <Typography variant="body1" textAlign='center'>
        Twitter: <Link sx={{ ml: 2 }} color="inherit" href="https://twitter.com/thewikinerd">@TheWikiNerd</Link>
      </Typography>
      <Box sx={FooterStyle}>
        <Footer />
      </Box>
    </>
  )
}
