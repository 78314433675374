import BoxMovie from '../../components/BoxMovie'
import Footer from '../../components/Footer'
import MovieListSkeleton from '../../components/MovieListSkeleton'
import SingleLineImageList from '../../components/SingleLineImageList'
import { useMovie } from '../../context/MovieContext'
import Movie from '../../interfaces/Movie'
import { Fragment } from 'react'
import IDisplayMovies from '../../interfaces/DisplayMovies'
import Title from '../../components/SEO/Title'

interface ISkeletonProps {
  skeletonKey: string
}

interface IDisplayProps {
  data: IDisplayMovies
}

export default function HomePage() {
  const { nowPlaying, upcoming, popular, topRated } = useMovie()

  const DisplayMovies = ({ data }: IDisplayProps) => {
    return <SingleLineImageList title={data.title} key={data.title}>
      {
        data.movies.map((movie: Movie) => {
          return (
            <Fragment key={`${data.title}-${movie.id}`}>
              <BoxMovie key={movie?.id} movie={movie} />
            </Fragment>
          )
        })
      }
    </SingleLineImageList>
  }
  const SkeletonDisplayMovies = ({ skeletonKey }: ISkeletonProps) => {
    return <SingleLineImageList title='' key={skeletonKey}>
      {
        Array(6).fill(null).map((value, index) =>
          <MovieListSkeleton key={index} />
        )
      }
    </SingleLineImageList>

  }
  return (
    <>
      <Title value='Gerencie seus Filmes' />
      {
        nowPlaying !== null ?
          <DisplayMovies data={nowPlaying} /> :
          <SkeletonDisplayMovies skeletonKey='now-playing' />
      }

      {
        popular !== null ?
          <DisplayMovies data={popular} /> :
          <SkeletonDisplayMovies skeletonKey='popular' />
      }

      {
        upcoming !== null ?
          <DisplayMovies data={upcoming} /> :
          <SkeletonDisplayMovies skeletonKey='upcoming' />
      }

      {
        topRated !== null ?
          <DisplayMovies data={topRated} /> :
          <SkeletonDisplayMovies skeletonKey='topRated' />
      }

      {(nowPlaying || popular || upcoming || topRated) && <Footer />}
    </>
  )
}
