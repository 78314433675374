export const FooterStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  bgcolor: '#673ab7',
  py: 1,
  mt: 0,
  px: 2,
}
export const BoxLinkStyle = {
  display: 'flex',
  color: 'white',
}
export const LinkStyle = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  color: 'white',
  marginLeft: 10,
  fontVariant: 'all-petite-caps'
}