export const BoxStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  overflow: 'hidden',
  margin: '10px 0 0 0'
}
export const TitleStyle = {
  mr: 2,
  display: 'flex'
}
export const BoxMoviesStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  overflow: 'auto',
  fontWeight: 'bold',
  maxWidth: '99%',
}