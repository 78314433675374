import { Crypto } from "../Crypto"

export const CacheStorage = {
  get(key: string): string | null {
    try {
      const data = localStorage.getItem(key)
      const decryptData = data ? Crypto.decrypt(data) : data
      return decryptData
    } catch (err) {
      return null
    }
  },

  delete(key: string): void{
    localStorage.removeItem(key)
  },

  set(key: string, data: any): void {
    const encryptData = Crypto.encrypt(data)
    localStorage.setItem(key, encryptData)
  }
}
