import { Box, Card, CardHeader, Typography } from '@mui/material'
import Moment from 'react-moment'
import ReactPlayer from 'react-player'
import Title from '../../components/SEO/Title'
import Video from '../../interfaces/Video'

interface Props {
  videos: Array<Video>
  title: string
}

export default function VideosTab({ videos, title }: Props) {
  return (
    <>
      <Title value={`${title} - Videos`} />
      {
        videos.length !== 0 ?
          videos.map((video: Video) => {
            return <Box
              key={video.id}
              sx={{
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '10px'
              }}
            >
              <Card sx={{ width: '100%', padding: '3px' }}>
                <CardHeader
                  title={video.name}
                  subheader={<Moment format='DD/MM/YYYY'>{video.published_at}</Moment>}
                />
                <ReactPlayer url={`https://www.youtube.com/watch?v=${video.keyword}`} controls={true} width='100%' />
              </Card>
            </Box>
          })
          :
          <Typography
            color='red'
            align='center'
            marginTop='10px'
          >
            Nenhum video até o momento.
          </Typography>
      }
    </>
  )
}
