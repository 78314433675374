import { Helmet } from 'react-helmet'

interface Props {
  value: string
}

export default function Title({ value }: Props) {
  return (
    <Helmet>
      <title>WikiNerd | {value}</title>
    </Helmet>
  )
}
