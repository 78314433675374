import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Person from '../../interfaces/Person'
import { BoxImageNotLoadedStyle, BoxImageStyle, BoxStyle, TypographyStyle, TypographySubTitleStyle } from './style'

interface Props {
  person?: Person
  subtitle: string
}

export default function BoxPerson({ person, subtitle }: Props) {
  const navigate = useNavigate()
  const [load, setLoad] = useState<boolean>(false)
  const stylePoster = load ? BoxImageStyle : { display: 'none' }

  return (
    <Box key={person?.id} sx={BoxStyle}>
      {
        !load &&
        <Box
          sx={BoxImageNotLoadedStyle}
          key={person?.slug}
          component='img'
          src='./../img/not_loaded.png'
          alt={`Foto de ${person?.name}`}
          title={`Foto de ${person?.name} não carregou`}
          width='120px'
          onClick={() => navigate(`/personalidades/${person?.slug}`)}
        />
      }
      <Box
        sx={stylePoster}
        key={person?.id}
        component='img'
        alt={person?.name}
        src={`https://www.themoviedb.org/t/p/w220_and_h330_face/${person?.profile_path}`}
        onClick={() => navigate(`/personalidades/${person?.slug}`)}
        onLoad={() => setLoad(true)}
      />
      <Typography
        sx={TypographyStyle}
        component='span'
        variant='subtitle1'
        align='center'
      >
        {person?.name}
      </Typography>
      <Typography
        sx={TypographySubTitleStyle}
        component='span'
        variant='caption'
      >
        {subtitle}
      </Typography>
    </Box>
  )
}
