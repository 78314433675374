import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import { get } from '../api'
import { CacheStorage } from '../helpers/CacheStorage'
import IDisplayMovies from '../interfaces/DisplayMovies'
import Movie from '../interfaces/Movie'
import User from '../interfaces/User'
import { GetStatusMovie } from '../helpers/GetStatusMovie'

interface Props {
  children: ReactNode
}

const MovieContext = createContext<any>({})

const MovieProvider = ({ children }: Props) => {
  const [nowPlaying, setNowPlaying] = useState<IDisplayMovies | null>(null)
  const [upcoming, setUpcoming] = useState<IDisplayMovies | null>(null)
  const [topRated, setTopRated] = useState<IDisplayMovies | null>(null)
  const [popular, setPopular] = useState<IDisplayMovies | null>(null)
  const navigate = useNavigate()

  const dataStorage = CacheStorage.get('wikinerd-usr-data')
  const initialData = dataStorage ? JSON.parse(dataStorage) : false
  const [userData, setUserData] = useState<User | boolean>(initialData)

  const storeUserData = (user: User) => {
    setUserData(user)
    CacheStorage.set('wikinerd-usr-data', JSON.stringify(user))
    setNowPlaying(null)
    setUpcoming(null)
    setTopRated(null)
    setPopular(null)
  }
  const removeUserData = () => {
    setUserData(false)
    CacheStorage.delete('wikinerd-usr-data')
    setNowPlaying(null)
    setUpcoming(null)
    setTopRated(null)
    setPopular(null)
    navigate('/login')
  }

  const changeStatus = (
    id: number,
    status: string,
    data: IDisplayMovies,
    setData: React.Dispatch<React.SetStateAction<IDisplayMovies | null>>
  ) => {
    let movie = data.movies.find((movie: Movie) => movie.id === id)
    if (movie) {
      let movies: IDisplayMovies
      // if (movie.userList) {
      //   movie.userList.status = status
      // } else {
      //   const watchedDate: Date = new Date()
      //   movie.userList = { status, watchedDate }
      // }
      movies = data
      setData(movies)
    }
  }

  const changeMovieStatus = (id: number, status: string) => {
    if (nowPlaying !== null) {
      changeStatus(id, status, nowPlaying, setNowPlaying)
    }
    if (popular !== null) {
      changeStatus(id, status, popular, setPopular)
    }
    if (upcoming !== null) {
      changeStatus(id, status, upcoming, setUpcoming)
    }
    if (topRated !== null) {
      changeStatus(id, status, topRated, setTopRated)
    }
  }

  const valueData = {
    userData,
    nowPlaying,
    upcoming,
    popular,
    topRated,
    changeMovieStatus,
    storeUserData,
    removeUserData
  }

  const getMovies = (
    term: string,
    setFunction: React.Dispatch<React.SetStateAction<IDisplayMovies | null>>,
    title: string,
    token: string | undefined
  ) => {
    get({
      term,
      currentPage: 1,
      order: {
        field: term === 'top-rated' ? 'vote_average' : 'popularity',
        direction: 'DESC'
      },
      token,
      adult: false
    })
      .then(async (movies) => {
        if (token) {
          movies.data = await GetStatusMovie(movies.data, token)
        }
        setFunction({
          title, movies: movies.data
        })
      })
      .catch((err) => {
        console.log(err)
        if (err.response.status === 401) {
          removeUserData()
        }
      })
  }

  useEffect(() => {
    (async () => {
      const user = userData
      const token = user && typeof user === 'object' ? user.token : undefined
      if (!nowPlaying) {
        getMovies('now-playing', setNowPlaying, 'Filmes em cartaz', token)
      }
      if (!popular) {
        getMovies('popular', setPopular, 'Filmes populares', token)
      }
      if (!upcoming) {
        getMovies('upcoming', setUpcoming, 'Filmes em breve', token)
      }
      if (!topRated) {
        getMovies('top-rated', setTopRated, 'Filmes mais avaliados', token)
      }
      // if (!movieData) {
      //   getHome(token)
      //     .then((movies) => setMovieData(movies))
      //     .catch((err) => {
      //       if (err.response.data === 'Token is invalid.') {
      //         removeUserData()
      //       }
      //     })
      // }
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  return <MovieContext.Provider value={valueData} >{children}</MovieContext.Provider>
}

function useMovie() {
  const context = useContext(MovieContext)

  if (!context) {
    throw new Error('useContext must be used in MovieProvider')
  }

  return context
}

export { MovieContext, MovieProvider, useMovie }
