import {
  Box, Link, Typography,
} from '@mui/material'
import Footer from '../../components/Footer'
import Title from '../../components/SEO/Title'
import { BoxPrimaryStyle, FooterStyle, LogosStyle, ParagraphStyle } from './style'

export default function AboutPage() {

  const pageTitle = 'Sobre'

  return (
    <>
      <Title value={pageTitle} />
      <Box sx={BoxPrimaryStyle}>
        <Typography variant="h3" component="h1">
          {pageTitle}
        </Typography>
        <Typography variant="h6" component="p" sx={ParagraphStyle}>
          WikiNerd não é um streaming de filmes piratas.
        </Typography>
        <Typography variant="body1" sx={ParagraphStyle}>
          WikiNerd é um aplicativo para você registrar os filmes que quer assistir, ou aqueles que já viu, podendo sinalizar se gostou ou não do filme, ou adiciona-lo a sua lista de favoritos.
        </Typography>
        <Typography variant="body1" sx={ParagraphStyle}>
          Você também ficará por dentro dos filmes em cartaz, próximos lançamentos, filmes mais populares e mais avaliados.
        </Typography>
        <Typography variant="body1" sx={ParagraphStyle}>
          Informações técnicas como sinopse, data lançamento, gêneros, duração, e elenco completo.
        </Typography>

        <Typography variant="body1">
          Os dados e imagens são providos pela api <Link color="inherit" href="https://www.themoviedb.org/" target='_blank'>TMDB</Link>
        </Typography>
        <Typography variant="body1">
          As informações de provedores de streaming são providas por <Link color="inherit" href="https://www.justwatch.com/" target='_blank'>JustWatch</Link>
        </Typography>

        <Box sx={LogosStyle}>
          <Link color="inherit" href="https://www.themoviedb.org/" target='_blank' sx={{ mr: 5 }}>
            <Box
              component='img'
              src='./img/tmdb.svg'
              alt='Logo TMDb'
              title='This product uses the TMDB API but is not endorsed or certified by TMDB'
              width='80px'
            />
          </Link>
          <Link color="inherit" href="https://www.justwatch.com/" target='_blank'>
            <Box
              component='img'
              src='./img/justwatch.svg'
              alt='Logo Just Watch'
              title='This product uses the JustWatch but is not endorsed or certified by JustWatch'
              width='120px'
            />
          </Link>
        </Box>
      </Box>
      <Box sx={FooterStyle}>
        <Footer />
      </Box>
    </>
  )
}
