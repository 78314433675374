import { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Link, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import BasePage from './BasePage'
import { register } from '../../api'
import { useMovie } from '../../context/MovieContext'
import { Typography } from '@mui/material'

export default function LoginPage() {
  const [error, setError] = useState<string | null>(null)
  const { storeUserData } = useMovie()
  const navigate = useNavigate()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const name = data.get('name')
    const email = data.get('email')
    const password = data.get('password')
    if (typeof name === 'string' && typeof email === 'string' && typeof password === 'string') {
      register({ name, email, password })
        .then((response) => {
          storeUserData(response.data)
          navigate('/')
        })
        .catch(err => setError(err.response.data.message))
    }
  }

  return (
    <BasePage title='Inscrever-se' error={error} setError={setError}>
      <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoComplete='given-name'
              name='name'
              required
              fullWidth
              id='name'
              label='Nome'
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id='email'
              label='Email'
              name='email'
              autoComplete='email'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name='password'
              label='Senha'
              type='password'
              id='password'
              autoComplete='new-password'
            />
            <Typography
              component='p'
              variant='caption'
              mt={2}
            >
              As senhas devem conter 8 caracteres, sendo um caracter numérico, um minúsculo, um maiúsculo e um simbolo.
            </Typography>
          </Grid>
        </Grid>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
        >
          Inscrever-se
        </Button>
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <Button variant='text' component={Link} to='/login'>
              Já tem uma conta? Entrar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </BasePage>
  )
}
