import {
  Box, Typography,
} from '@mui/material'
import Footer from '../../components/Footer'
import Title from '../../components/SEO/Title'
import { BoxPrimaryStyle, FooterStyle } from './style'

interface FaqProps {
  question: string,
  answer: string
}

function Paragraph({ question, answer }: FaqProps) {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant='subtitle2' component='p' textAlign='justify'>
        {question}
      </Typography>
      <Typography variant='subtitle1' component='p' textAlign='justify'>
        {answer}
      </Typography>
    </Box>
  )
}

export default function FaqPage() {

  const pageTitle = 'FAQ'

  return (
    <>
      <Title value={pageTitle} />
      <Typography variant='h3' component='h1' textAlign='center'>
        {pageTitle}
      </Typography>
      <Box sx={BoxPrimaryStyle}>
        <Paragraph
          question='O que é o WikiNerd?'
          answer='WikiNerd é um aplicativo para você registrar os filmes que quer assistir, ou aqueles que já viu, podendo sinalizar se gostou ou não do filme, ou adiciona-lo a sua lista de favoritos.'
        />

        <Paragraph
          question='Posso importar dados de log de outros serviços?'
          answer='Não há opção de importação no momento, mas você pode nos enviar o modelo do arquivo para analise.'
        />

        <Paragraph
          question='Posso exportar meus dados?'
          answer='Ainda não, mas essa opção será possivel em breve.'
        />
        <Paragraph
          question='O que faço quando encontro um bug?'
          answer='Você pode nós informar atrás dos canais de contato'
        />
      </Box>
      <Box sx={FooterStyle}>
        <Footer />
      </Box>
    </>
  )
}
